import React from "react";
import { testingEcho } from "../functions/testing";
import MyFileUpload from "../components/MyFileUpload";

import MyS3Files from "./MyS3Files";
// import { getAuth } from "firebase/auth";
const Home = () => {
  // let auth = getAuth();
  // let user = auth.currentUser;
  // console.log("Home user", getAuth());
  const fetchEcho = () => {
    testingEcho().then((res) => console.log(res.data));
  };
  // let question = "what is the context?"

  return (
    <div style={{ textAlign: "center", marginTop: "5vh" }}>
      <div>
        <MyFileUpload />
      </div>
      <br />
      <div>
        {" "}
        <p style={{ color: "blue" }}> Ai area ... </p>
      </div>
      <MyS3Files />

      <button color="blue" onClick={() => fetchEcho()}>
        Echo
      </button>
      {/* <button onClick={()=> fetchTriggerAi()}>Trigger Ai</button> */}
    </div>
  );
};

export default Home;
