import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AssistantIcon from "@mui/icons-material/Assistant";
import DeleteIcon from "@mui/icons-material/Delete";

const FilesList = ({ myFiles, talkToFile, handleRemove }) => {
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Documents to chat with
        </Typography>

        <List dense={true}>
          {myFiles.map((file) => (
            <ListItem
              key={file._id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemove(file._id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar
                onClick={() => talkToFile(file.uniqKey, file.title)}
              >
                <Avatar>
                  <AssistantIcon style={{ color: "blue" }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                onClick={() => talkToFile(file.uniqKey, file.title)}
                primary={file.title}
                secondary={false ? "No Title for this document" : null}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Box>
  );
};

export default FilesList;
