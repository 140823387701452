import React from "react";
import { Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import LoadingToRedirect from "../../pages/utils/LoadingToRedirect";
// import { currentUser } from "../functions/auth";
const UserRoute = ({ children }) => {
  let storedLoggedinUser = window.localStorage.getItem("clientUser");
  let parsedUser, userEmail;
  if (storedLoggedinUser) {
    // console.log("in if... ", storedLoggedinUser);
    parsedUser = JSON.parse(storedLoggedinUser);
    userEmail = parsedUser.email;
  } else if (storedLoggedinUser === null) {
    return <Navigate to="/account/login" replace />;
  }

  if (
    userEmail === undefined ||
    userEmail === null ||
    parsedUser === null ||
    storedLoggedinUser === null
  ) {
    return <Navigate to="/account/login" replace />;
  }

  return children;
};

export default UserRoute;
