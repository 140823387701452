import React, { useEffect, useState } from "react";
import { myFileUploadFunc } from "../functions/myFileUpload";
import { createFileDoc } from "../functions/fileDoc";
import { getAuth } from "firebase/auth";

// import axios from "axios";

const initialState = {
  writer: "",
  title: "init title",
  files: [],
  uniqKey: "",
};
const MyFileUpload = () => {
  // const fileInput = createRef();
  const [file, setFile] = useState();
  const [values, setValues] = useState(initialState);
  const [title, setTitle] = useState(null);

  let storedLoggedinUser = window.localStorage.getItem("clientUser");
  let user = JSON.parse(storedLoggedinUser);
  // console.log(user._id);
  const auth = getAuth();
  const AuthUser = auth.currentUser;

  const handleUpload = () => {
    // let storedLoggedinUser = window.localStorage.getItem("clientUser");
    // let parsedUser = JSON.parse(storedLoggedinUser);
    // let uploaderEmail = parsedUser.email;

    const formData = new FormData();
    formData.append("file", file);
    myFileUploadFunc(AuthUser.accessToken, formData)
      .then((res) => {
        console.log(res.data);
        const { file, s3URL, uniqKey } = res.data;
        // console.log("typeof uniqKey", typeof uniqKey);

        setTitle(file);
        if (res.status === 200) {
          alert("file uploaded successfully");
          createFileDocFromUpload(file, s3URL, uniqKey);
        }
      })
      .catch((er) => console.log(er));
  };
  const createFileDocFromUpload = async (file, s3URL, uniqKey) => {
    if (file) {
      let uploadedFilesArr = [];
      uploadedFilesArr.push(s3URL);
      createFileDoc(AuthUser.accessToken, {
        ...values,
        writer: user._id,
        title: file,
        files: uploadedFilesArr,
        uniqKey: uniqKey,
      })
        .then((res) => {
          console.log("created !!");
          // TODO

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      createFileDoc(
        AuthUser.accessToken,
        { ...values, writer: user._id, title: "No title for this document !" },
        user.token
      )
        .then((res) => {
          console.log("created !!");
          // TODO
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="app">
      <div>
        <input type="file" onChange={(e) => setFile(e.target.files[0])} />
        <button type="button" onClick={handleUpload}>
          Upload
        </button>
      </div>
    </div>
  );
};

export default MyFileUpload;
