import axios from "axios";
export const myFileUploadFunc = async (authtoken, formData) => {
  // console.log("#####", typeof authtoken);
  return await axios.post(
    `${process.env.REACT_APP_API}/my-file-upload`,
    formData,
    {
      headers: {
        Authorization: `${authtoken}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
