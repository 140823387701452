import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { Checkbox, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { createOrUpdateUser } from "../functions/auth";
import { Link } from "react-router-dom";

import { LOGGED_IN_USER } from "../reduxStore/actionTypes";
import { auth } from "../auth/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const navigate = useNavigate();
  // const { user } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();

  const rememberMeChecked = localStorage.getItem("rememberMe") ? true : false;

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(rememberMeChecked);
  const [loading, setLoading] = useState(false);

  const handler = async (values) => {
    setLoading(true);
    let dataToSubmit = {
      email: values.email,
      password: values.password,
    };
    //TODO:register function
    try {
      setLoading(true);
      const result = await signInWithEmailAndPassword(
        auth,
        dataToSubmit.email,
        dataToSubmit.password
      );
      const { user } = result;
      // console.log("result", result.user.accessToken);
      // console.log("FROM FIREBASE", result);
      const idTokenResult = await user.getIdTokenResult();
      // console.log("resulting..idTokenResult", idTokenResult.token);
      createOrUpdateUser("" + result.user.accessToken)
        .then((res) => {
          // console.log("createOrUpdateUser LOGIN", res);
          //   setId(res.data._id);
          // console.log("res InLog in...", res.data);
          let clientUser = res.data;
          window.localStorage.setItem("clientUser", JSON.stringify(clientUser));
          dispatch({
            type: LOGGED_IN_USER,
            payload: {
              name: res.data.name,
              email: res.data.email,
              token: result.user.accessToken,
            },
          });
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          setFormErrorMessage(err);
        });
      //   props.history.push("/");
    } catch (err) {
      // alert.show("Wrong password.0", {
      //   type: types.ERROR,
      // });
      setLoading(false);
      console.log(err);
    }
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className="sign-in-container">
      <div className="sign-in-bottom">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required"),
            password: Yup.string()
              .min(6, "Password must be at least 6 characters")
              .required("Password is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handler(values);

            setSubmitting(false);
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,

              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <div className="d-flex justify-content-center ">
                {loading ? (
                  <h4 className="text-danger">Loading...</h4>
                ) : (
                  <div className="log-in-form">
                    <br />

                    <br />
                    <div>
                      {/* <FacebookLogin
        appId="373040070679820"
        autoLoad={false}
        size={"small"}
        callback={() => console.log("not ready yet")}
        approvalPrompt="force"
        prompt="consent"

        // prompt="consent"
      /> */}
                    </div>
                    <br />
                    <form onSubmit={handleSubmit}>
                      <Form.Item required>
                        <Input
                          id="email"
                          style={{
                            fontSize: "18px",
                            fontFamily: "Times New Roman, Times, serif ",
                            borderRadius: "15px",
                          }}
                          prefix={
                            <UserOutlined
                              style={{
                                color: "rgba(0,0,0,.25)",
                                height: "18px",
                              }}
                            ></UserOutlined>
                          }
                          placeholder={"Email"}
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                        {errors.email && touched.email && (
                          <div
                            className="input-feedback"
                            style={{ color: "red" }}
                          >
                            {errors.email}
                          </div>
                        )}
                      </Form.Item>

                      <Form.Item required>
                        <Input
                          style={{
                            fontSize: "18px",
                            fontFamily: "Times New Roman, Times, serif ",
                            borderRadius: "15px",
                          }}
                          id="password"
                          prefix={
                            <LockOutlined
                              style={{
                                color: "rgba(0,0,0,.25)",
                              }}
                            ></LockOutlined>
                          }
                          placeholder={"Password "}
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.password && touched.password
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                        {errors.password && touched.password && (
                          <div
                            className="input-feedback"
                            style={{ color: "red" }}
                          >
                            {errors.password}
                          </div>
                        )}
                      </Form.Item>

                      {formErrorMessage && (
                        <label>
                          <p
                            style={{
                              color: "#ff0000bf",
                              fontSize: "0.7rem",
                              border: "1px solid",
                              padding: "1rem",
                              borderRadius: "10px",
                            }}
                          >
                            {formErrorMessage}
                          </p>
                        </label>
                      )}

                      <Form.Item>
                        <Checkbox
                          style={{
                            color: "#838383",
                            fontFamily: "Times New Roman, Times, serif ",
                            fontSize: "14px",
                          }}
                          id="rememberMe"
                          onChange={handleRememberMe}
                          checked={rememberMe}
                        >
                          rememberMe
                        </Checkbox>
                        {/* <Link
                          className="login-form-forgot mb-4"
                          to="/forgot/password"
                          style={{
                            float: "right",
                            fontFamily: "Times New Roman, Times, serif ",
                            fontSize: "14px",
                          }}
                        >
                          Forgot password?
                        </Link> */}
                        <div>
                          <button
                            id="sign-in-btn"
                            type="submit"
                            disabled={isSubmitting}
                            onSubmit={handleSubmit}
                          >
                            Sign in
                          </button>
                          {/* <Button
                      type="primary"
                      htmlType="submit"
                      className="btn btn-primary"
                      style={{ minWidth: "100%" }}
                      disabled={isSubmitting}
                      onSubmit={handleSubmit}></Button> */}
                        </div>

                        {/* <p className=" font-italic " style={{ color: "white" }}>
                    {t("Don't have an account yet?.0")}
                  </p> */}
                        <button
                          id="sig-in-register-btn"
                          type="submit"
                          disabled={isSubmitting}
                          onSubmit={handleSubmit}
                        >
                          <a href="/register">Register now</a>
                        </button>
                      </Form.Item>
                    </form>
                  </div>
                )}
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
