import axios from "axios";

export const testingEcho = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API}/test-ai`,

    {}
  );
};
export const fetchSmrtAnswer = async (question, indexName) => {
  // console.log("question", question, "indexName", indexName);
  let parsedQuestion = JSON.stringify(question);
  let pureQuestion = parsedQuestion.replace(/[?=]/g, "");
  // console.log("pureQuestion", pureQuestion);
  return await axios.get(
    // /trigger-with-question/:id&:indexName
    `${process.env.REACT_APP_API}/trigger-with-question/${pureQuestion}/${indexName}`,

    {}
  );
};
