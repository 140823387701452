import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getMyS3Files, removeOne } from "../functions/fileDoc";
import FilesList from "./FilesList";
import { getAuth } from "firebase/auth";

const MyS3Files = () => {
  let storedLoggedinUser = window.localStorage.getItem("clientUser");
  let user = JSON.parse(storedLoggedinUser);

  const [userToken, setUserToken] = useState("");
  // console.log(user._id);
  const navigate = useNavigate();
  const [mys3Files, setMys3Files] = useState([]);

  getAuth().onAuthStateChanged(function (user) {
    if (user) {
      setUserToken(user.accessToken);
      // User is signed in.
    } else {
      // No user is signed in.
    }
  });
  const handleRemove = (id) => {
    if (userToken) {
      // console.log(userToken);
      removeOne(id, userToken)
        .then((res) => {
          // console.log(res.data);

          alert(" File été supprimées ");
          loadMyAllFiles();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            // setLoading(false);
            console.log("Card deletion error ");
          }
        });
      loadMyAllFiles();
    }
  };

  const loadMyAllFiles = async () => {
    // await new Promise((resolve) => setTimeout(resolve, 10000));
    if (user) {
      getMyS3Files(user._id)
        .then((res) => {
          // console.log("jere", res.data);
          setMys3Files(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const talkToFile = async (s3FileKey, fileTitle) => {
    if (userToken) {
      navigate("/chat-arena", {
        state: {
          currentFile: fileTitle,
          s3FileKey: s3FileKey,
          userToken: userToken,
        },
      });
    }
  };
  useEffect(() => {
    // Update the document title using the browser API

    loadMyAllFiles();
  }, []);

  return (
    <div>
      <h3> My Files on the Cloud</h3>

      <FilesList
        myFiles={mys3Files}
        talkToFile={talkToFile}
        handleRemove={handleRemove}
      />
    </div>
  );
};

export default MyS3Files;
