import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./App.css";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Register from "./forms/Register";
import { getAuth } from "firebase/auth";
import { CURRENT_USER } from "./reduxStore/actionTypes";
import { createOrUpdateUser } from "./functions/auth";
import ResponsiveAppBar from "./ResponsiveAppBar";
import Login from "./forms/Login";
import UserRoute from "./protectedRoutes/UserRoute";
import ChatArena from "./pages/ChatArena";

function App() {
  const [id, setId] = useState();
  const [userToken, setUserToken] = useState("");
  getAuth().onAuthStateChanged(function (user) {
    if (user) {
      setUserToken(user.accessToken);
      // User is signed in.
    } else {
      // No user is signed in.
    }
  });
  // let storedLoggedinUser = window.localStorage.getItem("clientUser");
  // let parsedUser = JSON.parse(storedLoggedinUser);
  // console.log(parsedUser.email);

  const dispatch = useDispatch();

  const auth = getAuth();
  const user = auth.currentUser;
  // console.log("user FIrebse auth", user);
  useEffect(() => {
    // console.log("USER APP>JS", user);
    if (userToken) {
      // console.log(userToken);
      createOrUpdateUser(userToken)
        .then((res) => {
          // console.log("createOrUpdateUser", res.data);
          setId(res.data._id);

          // console.log("APP.Js", res.data);
          dispatch({
            type: CURRENT_USER,
            payload: {
              name: res.data.name,
              email: res.data.email,
              _id: res.data._id,
              token: res.data.token,
            },
          });
        })
        .catch((err) => console.log("ERRRR", err));
    }
  }, [user, id]);

  return (
    <div className="app">
      <Suspense fallback={<div>Loading...</div>}></Suspense>
      <ResponsiveAppBar />

      <Routes>
        <Route path="/register/*" element={<Register />} />
        <Route exact path="/account/login" element={<Login />} />

        <Route
          path="/"
          element={
            <UserRoute>
              <Home />
            </UserRoute>
          }
        />
        <Route
          path="/chat-arena"
          element={
            <UserRoute>
              <ChatArena />
            </UserRoute>
          }
        />

        {/* <Route path="/*" element={<Home />} /> */}
      </Routes>
    </div>
  );
}

export default App;
