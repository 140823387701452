////////////////>>>>>>>>
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA7e8fqSlFWxkpDsnUattzEk6EqMAWPclw",
  authDomain: "chat4xfr.firebaseapp.com",
  projectId: "chat4xfr",
  storageBucket: "chat4xfr.appspot.com",
  messagingSenderId: "872236373529",
  appId: "1:872236373529:web:ad815c4d2e74f2c6723c88",
  measurementId: "G-ZC5YRCNQGS",
};

// Initialize Firebase

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
///////
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
////////

////////////////>>>>>>>>

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyA7e8fqSlFWxkpDsnUattzEk6EqMAWPclw",
//   authDomain: "chat4xfr.firebaseapp.com",
//   projectId: "chat4xfr",
//   storageBucket: "chat4xfr.appspot.com",
//   messagingSenderId: "872236373529",
//   appId: "1:872236373529:web:ad815c4d2e74f2c6723c88",
//   measurementId: "G-ZC5YRCNQGS",
// };

// // Initialize Firebase

// // const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);
// ///////
// const app = initializeApp(firebaseConfig);
// console.log(app);
// export const auth = getAuth(app);
