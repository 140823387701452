import { CURRENT_USER, LOGGED_IN_USER, SIGNED_OUT } from "../actionTypes";

export default function user(state = null, action) {
  switch (action.type) {
    case CURRENT_USER:
      return action.payload;
    case LOGGED_IN_USER:
      return action.payload;
    case SIGNED_OUT:
      return action.payload;
    default:
      return state;
  }
}
