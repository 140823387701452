import axios from "axios";

export const createFileDoc = async (authtoken, fileDoc) =>
  await axios.post(`${process.env.REACT_APP_API}/fileDoc`, fileDoc, {
    headers: { Authorization: `${authtoken}` },
  });

export const getMyS3Files = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/get-my-s3-files/${id}`);

export const removeOne = async (id, authtoken) =>
  await axios.delete(
    `${process.env.REACT_APP_API}/remove-one-s3File/${id}`,

    {
      headers: {
        Authorization: authtoken,
      },
    }
  );

export const talkToFileFunc = async (s3FileKey, authtoken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/talk-to-s3File/${s3FileKey}`,

    {},
    {
      headers: {
        Authorization: authtoken,
      },
    }
  );
