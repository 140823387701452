import axios from "axios";

export const testApiRoute = async () => {
  console.log("in testApiRoute ");
  return await axios.get(`${process.env.REACT_APP_API}/test`);
};

export const createOrUpdateUser = async (authtoken) => {
  // console.log("in function /", authtoken);
  return await axios.post(
    `${process.env.REACT_APP_API}/create-or-update-user`,
    {},
    {
      headers: {
        Authorization: authtoken,
      },
    }
  );
};

export const currentUser = async (authtoken) => {
  // console.log("@@@@@@@@@@@@@@@@@@", authToken);
  return await axios.post(
    `${process.env.REACT_APP_API}/current-user`,

    {},
    {
      headers: {
        Authorization: authtoken,
      },
    }
  );
};

export const currentAdmin = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-admin`,
    {},
    {
      headers: {
        Authorization: authtoken,
      },
    }
  );
};
