import React, { useState, useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { auth } from "../auth/firebase";
import { LOGGED_IN_USER } from "../reduxStore/actionTypes";
import { createOrUpdateUser } from "../functions/auth";
import {
  createUserWithEmailAndPassword,
  // sendSignInLinkToEmail,
} from "firebase/auth";

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const Register = ({ history }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  useEffect(() => {
    setEmail(window.localStorage.getItem("emailForRegistration"));
  }, [history]);

  const handler = async (values) => {
    // console.log("Success:", values);

    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      // console.log("RESULT OF FIRE", result);
      if (result.user) {
        window.localStorage.setItem("emailForSignIn", email);
        createOrUpdateUser(result.user.accessToken)
          .then((res) => {
            // console.log("createOrUpdateUser", res);
            //   setId(res.data._id);
            dispatch({
              type: LOGGED_IN_USER,
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: result.user.accessToken,
              },
            });
            navigate("/account/login");
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };

  return (
    <div className="p-4" style={{ marginTop: "150px" }}>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handler}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Register;
