import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fetchSmrtAnswer } from "../functions/testing";

import { talkToFileFunc } from "../functions/fileDoc";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
const ChatArena = () => {
  const location = useLocation();

  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm your Assistant !",
      sentTime: "just now",
      sender: "Assistant",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const [loading, setLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState("");
  const [s3FileKey, setS3FileKey] = useState("");
  const [indexName, setIndexName] = useState("");
  const [userToken, setUserToken] = useState("");

  const systemMessage = {
    //  Explain things like you're talking to a software professional with 5 years of experience.
    role: "system",
    content:
      "Explain things like you're talking to a software professional with 2 years of experience.",
  };

  useEffect(() => {
    if (location.state) {
      let { currentFile, s3FileKey, userToken } = location.state;
      setCurrentFile(currentFile);
      setS3FileKey(s3FileKey);
      setUserToken(userToken);
    }
  }, [location, loading]);

  useEffect(() => {
    if (s3FileKey && userToken) {
      talkToFileFunc(s3FileKey, userToken)
        .then((res) => {
          let { message } = res.data;
          setIndexName(message);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [s3FileKey]);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);

    await processChatMessage(newMessages);
  };
  async function processChatMessage(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });
    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };
    if (indexName) {
      await fetchSmrtAnswer(chatMessages, indexName).then((res) => {
        // setAied(res.data);

        setMessages([
          ...chatMessages,
          {
            message: res.data,
            sender: "ChatGPT",
          },
        ]);
      });
    }
    setIsTyping(false);
  }
  if (loading) {
    return (
      <div style={{ margin: "10em" }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <CircularProgress size="5rem" />
        </Box>
      </div>
    );
  } else {
    return (
      <div className="App">
        <h2>Chating Arena with file {currentFile}</h2>

        <br />

        <div style={{ height: "600px", width: "900px" }}>
          <MainContainer>
            <ChatContainer>
              <MessageList
                scrollBehavior="smooth"
                typingIndicator={
                  isTyping ? (
                    <TypingIndicator content="Virtual Assistant is typing" />
                  ) : null
                }
              >
                {messages.map((message, i) => {
                  // console.log(message);
                  return <Message key={i} model={message} />;
                })}
              </MessageList>
              <MessageInput
                placeholder="Ask me something"
                onSend={handleSend}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
    );
  }
};

export default ChatArena;
